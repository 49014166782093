<template>
  <ModalOnOverlay
    @close="$emit('close')"
  >
    <template #header>
      {{ $t('contactUs') }}
    </template>
    <template #body>
      <HelpCenterQuestionForm/>
    </template>
    <template #buttons>
      <simple-button
        :value="$t('sendMsg')"
        :transparent="true"
        @click="$emit('send')"
      />
      <WarnButton
        :value="$t('cancel')"
        @click="$emit('close')"
      />
    </template>
  </ModalOnOverlay>
</template>

<script>
import HelpCenterQuestionForm from '@/components/ui/forms/HelpCenterQuestionForm'
import ModalOnOverlay from '@/components/ui/modals/ModalOnOverlay'
import WarnButton from '@/components/ui/buttons/WarnButton'

export default {
  name: 'HelpCenterQuestionModal',
  components: {
    HelpCenterQuestionForm,
    ModalOnOverlay,
    WarnButton,
  },
}
</script>

<style scoped>

</style>
