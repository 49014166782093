<template>
  <Form
    v-slot="{ meta }"
    as="div"
    class="question-form form"
  >
    <input-with-label-validation
      rules="required"
      name="companyName"
      :value="question.companyName"
      :label="$t('yourName')"
      :placeholder="$t('yourName')"
      :required="true"
      @input="(value) => { updateQuestion('companyName', value) }"
    />
    <input-with-label-validation
      rules="required|email"
      name="email"
      type="email"
      :value="question.email"
      :label="$t('eMail')"
      :placeholder="$t('eMail')"
      :required="true"
      @input="(value) => { updateQuestion('email', value) }"
    />
    <input-with-label-validation
      rules="required"
      :name="$t('models.description')"
      :label="$t('models.description')"
      :required="true"
    >
      <template #input>
            <textarea
              class="question-text model-input base-textarea"
              name="message"
              :placeholder="$t('models.description')"
              :value="question.message"
              @input="updateQuestionNative"
            ></textarea>
      </template>
    </input-with-label-validation>
  </Form>
</template>

<script>
import { Form } from 'vee-validate'
import { mapState, mapGetters } from 'vuex'
import InputWithLabelValidation from './InputWithLabelValidation'

export default {
  name: 'HelpCenterQuestionForm',
  components: {
    InputWithLabelValidation,
    Form,
  },
  computed: {
    ...mapState({ question: state => state.helpCenter.userQuestion }),
    ...mapGetters([
      'profile',
    ]),
  },
  methods: {
    updateQuestionNative ($event) {
      this.$store.commit('updateQuestion', {
        name: $event.target.name,
        value: $event.target.value,
      })
    },
    updateQuestion (name, value) {
      this.$store.commit('updateQuestion', {
        name,
        value,
      })
    },
  },
  mounted () {

  },
}
</script>

<style scoped lang="scss">
.question-text {
  height: 96px;
  resize: none;
  padding: 15px;
}
</style>
